
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 流量卡变更流水
 */
export default defineComponent({
  name: 'SimCardFlow',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'orderno',
              label: '申请单号'
            },
            {
              field: 'uid',
              label: '代理ID',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            }
          ]
        },
        remote: {
          action: https?.market.pageSimCardFlow
        }
      },
      cols: [
        {
          field: 'orderno',
          label: '申请单号',
          ability: 'copy',
          width: 240
        },
        {
          field: 'uid',
          label: '代理ID'
        },
        {
          field: 'simCardTypeCode',
          label: '流量卡编码',
        },
        {
          field: 'backerno',
          label: '渠道',
          options: mapper?.device.backerNo
        },
        {
          field: 'fromProfit',
          label: '原费率'
        },
        {
          field: 'toProfit',
          label: '新费率'
        },
        {
          field: 'createTime',
          label: '创建时间'
        }
      ]
    };

    return { tableOpts };
  }
});
